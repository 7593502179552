/*----------------- Global
*/

html,
.section {
  // background: #E0EAFC;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #CFDEF3, #E0EAFC);  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #CFDEF3, #E0EAFC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

* {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-size: 1.8em;
}

h4 {
  font-weight: 400;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Poppins', sans-serif;
  letter-spacing: -0.05em;
}

b {
  font-weight: 700;
}

.clickable {
  cursor: pointer;
}

.padded {
  margin: 1.33em 0;
}

.nav-button {
  padding: 3px;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;

  &.active {
    text-decoration: underline;
  }
}

.action-button {
  margin: 3px;
  padding: 3px;
  background-color: transparent;
  min-width: 10px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.9;
  border: 2px solid transparent;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;

  &.bigboy {
    background-color: #eee;
    padding: 1em;
  }

  &.tiny {
    font-size: 0.5em;
    line-height: 2.5em;
    pointer-events: none;
  }

  &.active {
    background-color: #eee;
    border: 2px solid black;
  }

  &:hover {
    background-color: #ccf;
  }
}

.clipped {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 210px;
  white-space: nowrap;
}

.centered {
  text-align: center;
}

.bigbottom {
  min-height: 100vh;
}

/*----------------- Sections
*/

.section {
  &.navigation {
    background-color: #ddd;
    margin: 1em 0;
  }
  &.toc {
    // background: #CFDEF3;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to right, #E0EAFC, #CFDEF3);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to right, #E0EAFC, #CFDEF3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: #eee;
    color: #333;
    border-radius: 10px;
    max-height: 30vh;
    overflow-y: auto;
    -webkit-box-shadow: inset 0px 0px 15px 2px rgba(118,0,118,0.2);
    -moz-box-shadow: inset 0px 0px 15px 2px rgba(118,0,118,0.2);
    box-shadow: inset 0px 0px 15px 2px rgba(118,0,118,0.2);
  }
  &.timeline {
    padding-top: 6px;
    background-color: #fff;
  }
  &.detail {
    background-color: #fff;
  }
  &.footer {
    background-color: #fff;
  }
}

.unified-timeline-navigator-container {
  @media screen and (min-width: 701px) and (max-width: 960px) {
    // zoom: 0.7;
  }
  @media screen and (min-width: 501px) and (max-width: 700px) {
    // zoom: 0.54;
  }
  @media screen and (min-width: 401px) and (max-width: 500px) {
    // zoom: 0.37;
  }
  @media screen and (min-width: 361px) and (max-width: 400px) {
    // zoom: 0.34;
  }
  @media screen and (max-width: 360px) {
    // zoom: 0.29;
  }
}

/*----------------- Flexbox
*/

.flex-container {
  display: flex;
  justify-content: space-between;

  &.flex-wrap {
    flex-wrap: wrap;
  }

  .flex-item {
    flex-grow: 0;
  }
}

/*-----------------  Color Matrix
*/

.stroke01 { stroke: rgb(255,0,0); }
.stroke02 { stroke: rgb(242,162,0); }
.stroke03 { stroke: rgb(163,217,163); }
.stroke04 { stroke: rgb(0,116,217); }
.stroke05 { stroke: rgb(76,22,89); }
.stroke06 { stroke: rgb(229,0,0); }
.stroke07 { stroke: rgb(76,57,19); }
.stroke08 { stroke: rgb(13,51,23); }
.stroke09 { stroke: rgb(29,75,115); }
.stroke10 { stroke: rgb(170,45,179); }
.stroke11 { stroke: rgb(217,0,0); }
.stroke12 { stroke: rgb(127,102,0); }
.stroke13 { stroke: rgb(57,230,126); }
.stroke14 { stroke: rgb(0,97,242); }
.stroke15 { stroke: rgb(255,128,246); }
.stroke16 { stroke: rgb(166,41,41); }
.stroke17 { stroke: rgb(255,217,64); }
.stroke18 { stroke: rgb(0,153,102); }
.stroke19 { stroke: rgb(134,152,179); }
.stroke20 { stroke: rgb(242,0,129); }
.stroke21 { stroke: rgb(115,86,86); }
.stroke22 { stroke: rgb(77,73,57); }
.stroke23 { stroke: rgb(64,255,217); }
.stroke24 { stroke: rgb(0,32,242); }
.stroke25 { stroke: rgb(217,108,166); }
.stroke26 { stroke: rgb(229,130,115); }
.stroke27 { stroke: rgb(191,185,96); }
.stroke28 { stroke: rgb(89,179,161); }
.stroke29 { stroke: rgb(38,43,77); }
.stroke30 { stroke: rgb(115,57,88); }
.stroke31 { stroke: rgb(89,24,0); }
.stroke32 { stroke: rgb(176,179,134); }
.stroke33 { stroke: rgb(67,89,88); }
.stroke34 { stroke: rgb(41,41,166); }
.stroke35 { stroke: rgb(255,191,225); }
.stroke36 { stroke: rgb(89,57,45); }
.stroke37 { stroke: rgb(61,77,0); }
.stroke38 { stroke: rgb(0,95,102); }
.stroke39 { stroke: rgb(182,182,242); }
.stroke40 { stroke: rgb(51,38,45); }
.stroke41 { stroke: rgb(204,82,0); }
.stroke42 { stroke: rgb(195,230,57); }
.stroke43 { stroke: rgb(128,247,255); }
.stroke44 { stroke: rgb(170,121,242); }
.stroke45 { stroke: rgb(127,0,51); }
.stroke46 { stroke: rgb(242,206,182); }
.stroke47 { stroke: rgb(136,255,0); }
.stroke48 { stroke: rgb(153,201,204); }
.stroke49 { stroke: rgb(87,77,102); }
.stroke50 { stroke: rgb(242,0,65); }
.stroke51 { stroke: rgb(255,136,0); }
.stroke52 { stroke: rgb(66,166,0); }
.stroke53 { stroke: rgb(45,152,179); }
.stroke54 { stroke: rgb(34,0,51); }
.stroke55 { stroke: rgb(51,13,18); }
.stroke56 { stroke: rgb(127,68,0); }
.stroke57 { stroke: rgb(14,102,0); }
.stroke58 { stroke: rgb(0,170,255); }
.stroke59 { stroke: rgb(106,64,128); }
.stroke60 { stroke: rgb(255,128,145); }
.stroke61 { stroke: rgb(178,137,89); }
.stroke62 { stroke: rgb(90,115,86); }
.stroke63 { stroke: rgb(13,38,51); }
.stroke64 { stroke: rgb(204,0,255); }

.fill01 { fill: rgb(255,0,0); }
.fill02 { fill: rgb(242,162,0); }
.fill03 { fill: rgb(163,217,163); }
.fill04 { fill: rgb(0,116,217); }
.fill05 { fill: rgb(76,22,89); }
.fill06 { fill: rgb(229,0,0); }
.fill07 { fill: rgb(76,57,19); }
.fill08 { fill: rgb(13,51,23); }
.fill09 { fill: rgb(29,75,115); }
.fill10 { fill: rgb(170,45,179); }
.fill11 { fill: rgb(217,0,0); }
.fill12 { fill: rgb(127,102,0); }
.fill13 { fill: rgb(57,230,126); }
.fill14 { fill: rgb(0,97,242); }
.fill15 { fill: rgb(255,128,246); }
.fill16 { fill: rgb(166,41,41); }
.fill17 { fill: rgb(255,217,64); }
.fill18 { fill: rgb(0,153,102); }
.fill19 { fill: rgb(134,152,179); }
.fill20 { fill: rgb(242,0,129); }
.fill21 { fill: rgb(115,86,86); }
.fill22 { fill: rgb(77,73,57); }
.fill23 { fill: rgb(64,255,217); }
.fill24 { fill: rgb(0,32,242); }
.fill25 { fill: rgb(217,108,166); }
.fill26 { fill: rgb(229,130,115); }
.fill27 { fill: rgb(191,185,96); }
.fill28 { fill: rgb(89,179,161); }
.fill29 { fill: rgb(38,43,77); }
.fill30 { fill: rgb(115,57,88); }
.fill31 { fill: rgb(89,24,0); }
.fill32 { fill: rgb(176,179,134); }
.fill33 { fill: rgb(67,89,88); }
.fill34 { fill: rgb(41,41,166); }
.fill35 { fill: rgb(255,191,225); }
.fill36 { fill: rgb(89,57,45); }
.fill37 { fill: rgb(61,77,0); }
.fill38 { fill: rgb(0,95,102); }
.fill39 { fill: rgb(182,182,242); }
.fill40 { fill: rgb(51,38,45); }
.fill41 { fill: rgb(204,82,0); }
.fill42 { fill: rgb(195,230,57); }
.fill43 { fill: rgb(128,247,255); }
.fill44 { fill: rgb(170,121,242); }
.fill45 { fill: rgb(127,0,51); }
.fill46 { fill: rgb(242,206,182); }
.fill47 { fill: rgb(136,255,0); }
.fill48 { fill: rgb(153,201,204); }
.fill49 { fill: rgb(87,77,102); }
.fill50 { fill: rgb(242,0,65); }
.fill51 { fill: rgb(255,136,0); }
.fill52 { fill: rgb(66,166,0); }
.fill53 { fill: rgb(45,152,179); }
.fill54 { fill: rgb(34,0,51); }
.fill55 { fill: rgb(51,13,18); }
.fill56 { fill: rgb(127,68,0); }
.fill57 { fill: rgb(14,102,0); }
.fill58 { fill: rgb(0,170,255); }
.fill59 { fill: rgb(106,64,128); }
.fill60 { fill: rgb(255,128,145); }
.fill61 { fill: rgb(178,137,89); }
.fill62 { fill: rgb(90,115,86); }
.fill63 { fill: rgb(13,38,51); }
.fill64 { fill: rgb(204,0,255); }

.active.background-color1 { background-color: rgba(255,0,0,0.4); border-color: rgba(255,0,0,1); }
.active.background-color2 { background-color: rgba(242,162,0,0.4); border-color: rgba(242,162,0,1); }
.active.background-color3 { background-color: rgba(163,217,163,0.4); border-color: rgba(163,217,163,1); }
.active.background-color4 { background-color: rgba(0,116,217,0.4); border-color: rgba(0,116,217,1); }
.active.background-color5 { background-color: rgba(76,22,89,0.4); border-color: rgba(76,22,89,1); }
.active.background-color6 { background-color: rgba(229,0,0,0.4); border-color: rgba(229,0,0,1); }
.active.background-color7 { background-color: rgba(76,57,19,0.4); border-color: rgba(76,57,19,1); }
.active.background-color8 { background-color: rgba(13,51,23,0.4); border-color: rgba(13,51,23,1); }
.active.background-color9 { background-color: rgba(29,75,115,0.4); border-color: rgba(29,75,115,1); }
.active.background-color10 { background-color: rgba(170,45,179,0.4); border-color: rgba(170,45,179,1); }
.active.background-color11 { background-color: rgba(217,0,0,0.4); border-color: rgba(217,0,0,1); }
.active.background-color12 { background-color: rgba(127,102,0,0.4); border-color: rgba(127,102,0,1); }
.active.background-color13 { background-color: rgba(57,230,126,0.4); border-color: rgba(57,230,126,1); }
.active.background-color14 { background-color: rgba(0,97,242,0.4); border-color: rgba(0,97,242,1); }
.active.background-color15 { background-color: rgba(255,128,246,0.4); border-color: rgba(255,128,246,1); }
.active.background-color16 { background-color: rgba(166,41,41,0.4); border-color: rgba(166,41,41,1); }
.active.background-color17 { background-color: rgba(255,217,64,0.4); border-color: rgba(255,217,64,1); }
.active.background-color18 { background-color: rgba(0,153,102,0.4); border-color: rgba(0,153,102,1); }
.active.background-color19 { background-color: rgba(134,152,179,0.4); border-color: rgba(134,152,179,1); }
.active.background-color20 { background-color: rgba(242,0,129,0.4); border-color: rgba(242,0,129,1); }
.active.background-color21 { background-color: rgba(115,86,86,0.4); border-color: rgba(115,86,86,1); }
.active.background-color22 { background-color: rgba(77,73,57,0.4); border-color: rgba(77,73,57,1); }
.active.background-color23 { background-color: rgba(64,255,217,0.4); border-color: rgba(64,255,217,1); }
.active.background-color24 { background-color: rgba(0,32,242,0.4); border-color: rgba(0,32,242,1); }
.active.background-color25 { background-color: rgba(217,108,166,0.4); border-color: rgba(217,108,166,1); }
.active.background-color26 { background-color: rgba(229,130,115,0.4); border-color: rgba(229,130,115,1); }
.active.background-color27 { background-color: rgba(191,185,96,0.4); border-color: rgba(191,185,96,1); }
.active.background-color28 { background-color: rgba(89,179,161,0.4); border-color: rgba(89,179,161,1); }
.active.background-color29 { background-color: rgba(38,43,77,0.4); border-color: rgba(38,43,77,1); }
.active.background-color30 { background-color: rgba(115,57,88,0.4); border-color: rgba(115,57,88,1); }
.active.background-color31 { background-color: rgba(89,24,0,0.4); border-color: rgba(89,24,0,1); }
.active.background-color32 { background-color: rgba(176,179,134,0.4); border-color: rgba(176,179,134,1); }
.active.background-color33 { background-color: rgba(67,89,88,0.4); border-color: rgba(67,89,88,1); }
.active.background-color34 { background-color: rgba(41,41,166,0.4); border-color: rgba(41,41,166,1); }
.active.background-color35 { background-color: rgba(255,191,225,0.4); border-color: rgba(255,191,225,1); }
.active.background-color36 { background-color: rgba(89,57,45,0.4); border-color: rgba(89,57,45,1); }
.active.background-color37 { background-color: rgba(61,77,0,0.4); border-color: rgba(61,77,0,1); }
.active.background-color38 { background-color: rgba(0,95,102,0.4); border-color: rgba(0,95,102,1); }
.active.background-color39 { background-color: rgba(182,182,242,0.4); border-color: rgba(182,182,242,1); }
.active.background-color40 { background-color: rgba(51,38,45,0.4); border-color: rgba(51,38,45,1); }
.active.background-color41 { background-color: rgba(204,82,0,0.4); border-color: rgba(204,82,0,1); }
.active.background-color42 { background-color: rgba(195,230,57,0.4); border-color: rgba(195,230,57,1); }
.active.background-color43 { background-color: rgba(128,247,255,0.4); border-color: rgba(128,247,255,1); }
.active.background-color44 { background-color: rgba(170,121,242,0.4); border-color: rgba(170,121,242,1); }
.active.background-color45 { background-color: rgba(127,0,51,0.4); border-color: rgba(127,0,51,1); }
.active.background-color46 { background-color: rgba(242,206,182,0.4); border-color: rgba(242,206,182,1); }
.active.background-color47 { background-color: rgba(136,255,0,0.4); border-color: rgba(136,255,0,1); }
.active.background-color48 { background-color: rgba(153,201,204,0.4); border-color: rgba(153,201,204,1); }
.active.background-color49 { background-color: rgba(87,77,102,0.4); border-color: rgba(87,77,102,1); }
.active.background-color50 { background-color: rgba(242,0,65,0.4); border-color: rgba(242,0,65,1); }
.active.background-color51 { background-color: rgba(255,136,0,0.4); border-color: rgba(255,136,0,1); }
.active.background-color52 { background-color: rgba(66,166,0,0.4); border-color: rgba(66,166,0,1); }
.active.background-color53 { background-color: rgba(45,152,179,0.4); border-color: rgba(45,152,179,1); }
.active.background-color54 { background-color: rgba(34,0,51,0.4); border-color: rgba(34,0,51,1); }
.active.background-color55 { background-color: rgba(51,13,18,0.4); border-color: rgba(51,13,18,1); }
.active.background-color56 { background-color: rgba(127,68,0,0.4); border-color: rgba(127,68,0,1); }
.active.background-color57 { background-color: rgba(14,102,0,0.4); border-color: rgba(14,102,0,1); }
.active.background-color58 { background-color: rgba(0,170,255,0.4); border-color: rgba(0,170,255,1); }
.active.background-color59 { background-color: rgba(106,64,128,0.4); border-color: rgba(106,64,128,1); }
.active.background-color60 { background-color: rgba(255,128,145,0.4); border-color: rgba(255,128,145,1); }
.active.background-color61 { background-color: rgba(178,137,89,0.4); border-color: rgba(178,137,89,1); }
.active.background-color62 { background-color: rgba(90,115,86,0.4); border-color: rgba(90,115,86,1); }
.active.background-color63 { background-color: rgba(13,38,51,0.4); border-color: rgba(13,38,51,1); }
.active.background-color64 { background-color: rgba(204,0,255,0.4); border-color: rgba(204,0,255,1); }

.selection {
  stroke: transparent;
  fill: #99f;
  fill-opacity: 0.2;
}

.handle {
  fill: rgba(150,150,255,0.8);
  stroke: rgba(150,150,255,.5);
}

g.tick line {
  opacity: 0.2;
}

/*-----------------  Color Matrix
*/

.milestone {
  opacity: 0.5;
  font-size: 10px;

  line {
    stroke: #333;
    stroke-dasharray: (3,3);
  }
  text {
    fill: #333;
  }

}
